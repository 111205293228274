import React from "react";
import { useMutation } from "@apollo/client";
import {
  EnableProjectMutation,
  DisableProjectMutation,
} from "../gql/ActiveProjectToggleMutation";
import Button from "react-bootstrap/esm/Button";
import { MdCollections } from "react-icons/md";

const ActiveProjectToggle = ({ project }) => {
  const [enableProject] = useMutation(EnableProjectMutation);
  const [disableProject] = useMutation(DisableProjectMutation);

  const handleDisable = async () => {
    await disableProject({
      variables: { projectName: project.projectName },
    });
  };

  const handleEnable = async () => {
    await enableProject({
      variables: { projectName: project.projectName },
    });
  };

  return (
    <>
      {project.isActive ? (
        <Button
          title="Deactivate Project"
          size="sm"
          variant="danger"
          style={{ color: "white" }}
          onClick={() => handleDisable()}
        >
          <MdCollections />
        </Button>
      ) : (
        <Button
          title="Activate Project"
          size="sm"
          variant="success"
          style={{ color: "white" }}
          onClick={() => handleEnable()}
        >
          <MdCollections />
        </Button>
      )}
    </>
  );
};

export default ActiveProjectToggle;
