import { gql } from "@apollo/client";
const FailedUploadMutation = gql`
  mutation FailedUploadMutation(
    $filename: String!
    $problem: String
    $relativePath: String
    $uploadId: BigInt!
  ) {
    createFailedFile(
      input: {
        failedFile: {
          filename: $filename
          problem: $problem
          relativePath: $relativePath
          uploadId: $uploadId
        }
      }
    ) {
      failedFile {
        filename
      }
    }
  }
`;

export default FailedUploadMutation;
