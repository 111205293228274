import { gql } from "@apollo/client";

const ProjectsSubscription = gql`
  subscription ProjectsSubscription {
    listen(topic: "project_update") {
      query {
        allProjects(orderBy: PROJECT_NAME_ASC) {
          nodes {
            id
            projectName
            isActive
            uploadCount: uploadsByProjectName {
              count: totalCount
            }
            photos: photosByProjectName {
              count: totalCount
            }
            addedOn
          }
        }
      }
    }
  }
`;

export default ProjectsSubscription;
