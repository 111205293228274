import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Projects from "../gql/ProjectsQuery";
import AddProjectMutation from "../gql/AddProjectMutation";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/esm/Button";

const AddProject = () => {
  const [projectName, setProjectName] = useState("");
  const [projects, setProjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const { loading, error, data } = useQuery(Projects);
  const [addProject] = useMutation(AddProjectMutation);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!projectName || projectName === "") {
      setErrorMessage("Please input a Project Name above.");
      return;
    }

    if (projects.includes(projectName)) {
      setErrorMessage(
        "This project name already exists. Please check the Project Name in the input above or check the Projects table to see if it exists."
      );
      return;
    }

    try {
      const response = await addProject({
        variables: {
          projectName: projectName,
        },
      });

      if (response.data.errors && response.data.errors.length >= 1) {
        if (response.data.errors[0].errcode === "23505")
          setErrorMessage(
            "This project name already exists. Please check the Project Name in the input above or check the Projects table to see if it exists."
          );

        setErrorMessage("There was an error adding the project.");
        return;
      }

      if (!response.data.createProject.project.id) {
        setErrorMessage("There was an error adding the project.");
        return;
      }

      setProjectName("");
      setErrorMessage();
      e.target.reset();
    } catch (error) {
      setErrorMessage("There was an error adding the project.");
      console.error(error);
    }
  };

  useEffect(() => {
    data.allProjects.nodes.map((u) => {
      return setProjects((projects) => [projects, u.projectName]);
    });
  }, [data]);

  if (loading)
    return (
      <Spinner
        variant="primary"
        animation="grow"
        style={{ width: "400", height: "400" }}
      />
    );

  if (error) return <Alert>There was an issue with administration.</Alert>;

  if (data) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Add New Project</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form id="add-project-form" onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label htmlFor="new-project">Projects</Form.Label>
              <Form.Control
                className="my-2"
                isInvalid={errorMessage ? true : false}
                as="input"
                id="new-project"
                onChange={(e) => setProjectName(e.target.value)}
                value={projectName}
              />
            </Form.Group>
            {errorMessage && <Alert>{errorMessage}</Alert>}
            <Form.Group className="p-4 g-2">
              <Button
                size="sm"
                type="submit"
                form="add-project-form"
                style={{ float: "right", color: "white" }}
              >
                Add Project
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    );
  }
};

export default AddProject;
