import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const DateTimeFields = ({
  exif,
  dateTimeField,
  setDateTimeField,
  timeField,
  setTimeField,
  dateField,
  setDateField,
}) => {
  const [single, setSingle] = useState(true);

  const handleSingleDateTime = (e) => {
    if (!e.target.checked) {
      setTimeField();
      setDateField();
      setSingle(true);
      return;
    }

    if (e.target.checked) {
      setDateTimeField();
      setSingle(false);
      return;
    }
  };

  return (
    <>
      {single ? (
        <InputGroup size="sm" className="mb-2">
          <InputGroup.Text style={{ width: "40%" }}>
            DateTime Field
          </InputGroup.Text>
          <Form.Select
            disabled={!exif.length}
            value={dateTimeField}
            onChange={(e) => setDateTimeField(e.target.value)}
          >
            <option value=""></option>
            {exif?.map((e, i) => {
              return (
                <option key={i} value={e.key}>
                  {e.display} (e.g.: {e.example})
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
      ) : (
        <>
          <InputGroup size="sm" className="mb-2">
            <InputGroup.Text style={{ width: "40%" }}>
              Date Field
            </InputGroup.Text>
            <Form.Select
              disabled={!exif.length}
              value={dateField}
              onChange={(e) => setDateField(e.target.value)}
            >
              <option value=""></option>
              {exif?.map((e) => {
                return (
                  <option key={e.key} value={e.key}>
                    {e.display} (e.g.: {e.example})
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
          <InputGroup size="sm" className="mb-2">
            <InputGroup.Text style={{ width: "40%" }}>
              Time Field
            </InputGroup.Text>
            <Form.Select
              disabled={!exif.length}
              value={timeField}
              onChange={(e) => setTimeField(e.target.value)}
            >
              <option value=""></option>
              {exif?.map((e) => {
                return (
                  <option key={e.key} value={e.key}>
                    {e.display} (e.g.: {e.example})
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        </>
      )}
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text>Seperate Date Time Fields</InputGroup.Text>
        <InputGroup.Text>
          <Form.Check
            size="sm"
            type="switch"
            aria-label="show-hide-exif"
            disabled={!exif.length}
            checked={!single}
            onChange={(e) => handleSingleDateTime(e)}
          />
        </InputGroup.Text>
      </InputGroup>
    </>
  );
};

export default DateTimeFields;
