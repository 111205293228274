import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewPasswordMutation from "../gql/NewPasswordMutation";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useQuery, useMutation } from "@apollo/client";
import CurrentUser from "../gql/CurrentUser";

const NewPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [valid, setValid] = useState(false);

  const { loading, error, data } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  const [newPassword] = useMutation(NewPasswordMutation);

  if (localStorage.getItem("photo-token") === null) navigate("/login");

  useEffect(() => {
    if (data) {
      if (!data.currentUser.changePassword) navigate("/");
      setEmail(data.currentUser.email);
    }
  }, [data, navigate]);

  if (loading) return <Spinner />;
  if (error) navigate("/login");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkPassword !== password) {
      setValid(true);
      setErrorMessage("Passwords Do Not Match");
      return;
    }

    try {
      const response = await newPassword({
        variables: {
          password: password,
        },
      });

      if (!response.data.newPassword.boolean) {
        setErrorMessage("New Password Not Accepted");
      }

      navigate("/");
    } catch (error) {
      console.error(error);
      setErrorMessage("There was an error authenticating.");
    }
  };

  if (data.currentUser.changePassword) {
    return (
      <Modal centered show>
        <Modal.Header>
          <Modal.Title>
            <img
              alt="Thompson Consulting Service Logo"
              src="/logo192.png"
              width="65"
              height="65"
            />{" "}
            Thompson Consulting Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <Alert>{errorMessage}</Alert>}
          <p>
            There was a password reset request for {email}. Please enter a new
            password.
          </p>
          <Form onSubmit={handleSubmit} id="newPasswordForm">
            <Form.Group className="mb-3" controlId="newPasswordForm.Password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="New Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="newPasswordForm.CheckPassword"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                required
                isInvalid={valid}
                onChange={(e) => {
                  setCheckPassword(e.target.value);
                }}
                value={checkPassword}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ color: "white" }}
            type="submit"
            form="newPasswordForm"
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default NewPassword;
