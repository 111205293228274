import { gql } from "@apollo/client";

const FailedUploads = gql`
  query AllFailedUploads($uploadId: BigInt!) {
    allFailedFiles(condition: { uploadId: $uploadId }, orderBy: ID_ASC) {
      nodes {
        id
        relativePath
        filename
        problem
      }
    }
  }
`;

export default FailedUploads;
