import { gql } from "@apollo/client";

const AvailableProjects = gql`
  query AvailableProjects {
    allProjects(condition: { isActive: true }, orderBy: PROJECT_NAME_ASC) {
      nodes {
        projectName
      }
    }
  }
`;

export default AvailableProjects;
