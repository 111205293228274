import { gql } from "@apollo/client";

const UpdatePhotoGeom = gql`
  mutation updatePhotoGeom($uploadId: BigInt!) {
    updatePhotoGeom(input: { tupload: $uploadId }) {
      boolean
    }
  }
`;

export default UpdatePhotoGeom;
