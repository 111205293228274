import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ResetPasswordMutation from "../gql/ResetPassowrdMutation";

import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { BsPersonFillLock } from "react-icons/bs";

const ResetPassword = ({ user }) => {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [resetPassword] = useMutation(ResetPasswordMutation);

  const userId = user.appId;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await resetPassword({
        variables: { userId: userId, password: password },
      });

      if (!response.data.changePassword.boolean) {
        setErrorMessage(true);
        return;
      }
      setSuccessMessage(true);
      e.target.reset();
    } catch (error) {
      setErrorMessage(true);
      return;
    }
  };

  return (
    <>
      <Modal
        centered
        show={show}
        backdrop
        onHide={() => {
          setShow(false);
          setShowPassword(false);
          setPassword();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Reset password for {user.firstName} {user.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <Alert>There was a problem reseting this users password.</Alert>
          )}
          <Form onSubmit={handleSubmit} id="resetPasswordForm">
            <Form.Group className="mb-3" controlId="resetPasswordForm.Password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="New Password"
                className="has-feedback"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              ></Form.Control>
            </Form.Group>
          </Form>
          {successMessage && (
            <Alert>
              <p style={{ textAlign: "center" }}>
                The users password was successfully changed to:
              </p>
              <p style={{ textAlign: "center" }}>
                <h3 onClick={() => setShowPassword(!showPassword)}>
                  {password && showPassword ? (
                    password
                  ) : (
                    <>{"*".padEnd(password.length, "*")}</>
                  )}
                </h3>
              </p>
              <p style={{ textAlign: "center" }}>
                Click to reveal password to send to {user.firstName}{" "}
                {user.lastName}.
              </p>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            style={{ color: "white" }}
            form="resetPasswordForm"
            onClick={() => {
              setPassword();
              setSuccessMessage(false);
              setShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{ color: "white" }}
            type="submit"
            form="resetPasswordForm"
          >
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        title="Reset User Password"
        size="sm"
        variant="primary"
        style={{ color: "white" }}
        onClick={() => setShow(true)}
      >
        <BsPersonFillLock />
      </Button>
    </>
  );
};

export default ResetPassword;
