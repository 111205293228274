import { gql } from "@apollo/client";

const UploadsQuery = gql`
  query AllUploads {
    allUploads(orderBy: UPLOADED_DESC) {
      nodes {
        projectName
        projectByProjectName {
          isActive
        }
        imageCount
        id
        status
        uploaded
        zipFileName
        zipFileSize
        imageCount
        isBulk
        inQaqc
        userByUserId {
          appId
          email
          firstName
          lastName
        }
        failedFilesByUploadId {
          totalCount
        }
      }
    }
  }
`;

export default UploadsQuery;
