import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import EditQuery from "./gql/EditQuery";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Popover from "react-bootstrap/Popover";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import Collisions from "./Collisions";
import EditKeep from "./EditKeep";
import Reconcile from "./ReconcileButton";
import EditSubscription from "./gql/EditSubscription";
import * as Dayjs from "dayjs";

const Edits = () => {
  const [collisions, setCollisions] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const { data, loading, error, subscribeToMore } = useQuery(EditQuery, {
    fetchPolicy: "network-only",
  });

  subscribeToMore({
    document: EditSubscription,
    updateQuery: () => {
      return true;
    },
  });

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(data?.allQaqcs?.totalCount / itemsPerPage);

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading)
    return (
      <Spinner
        variant="primary"
        animation="grow"
        style={{ width: "400", height: "400" }}
      />
    );

  if (error) return <Alert>There was an issue with administration.</Alert>;

  const popover = (id, url) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>
          <Image src={url} alt={id} width={200} />
        </Popover.Body>
      </Popover>
    );
  };

  if (data) {
    return (
      <>
        <Row>
          <Col xl={12}>
            <Collisions setCollisions={setCollisions} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Edits ({data?.allQaqcs?.totalCount})</Card.Title>
                <Reconcile
                  editCount={data?.allQaqcs?.totalCount}
                  collisionCount={collisions}
                />
              </Card.Header>
              <Card.Body>
                {data?.allQaqcs?.nodes.length > 0 ? (
                  <Table
                    responsive="md"
                    size="sm"
                    striped
                    hover
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>Photo ID</th>
                        <th>Edited On</th>
                        <th>Editor</th>
                        <th>Current State</th>
                        <th>Edited State</th>
                        <th>Keep?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.allQaqcs.nodes
                        .slice(indexOfFirstItem, indexOfLastItem)
                        .map((d, i) => (
                          <tr key={d.id}>
                            <td>
                              <OverlayTrigger
                                placement="right"
                                key={i}
                                overlay={popover(d.id, d.url)}
                              >
                                <span>{d.id}</span>
                              </OverlayTrigger>
                            </td>
                            <td>
                              {Dayjs(d.editDate).format("YYYY-MM-DD h:mm")}
                            </td>
                            <td>{d.editor}</td>
                            <td>{d.photoById.isActive ? "True" : "False"}</td>
                            <td
                              style={{
                                backgroundColor:
                                  d.isActive !== d.photoById.isActive
                                    ? "tomato"
                                    : "none",
                              }}
                            >
                              {d.isActive ? "True" : "False"}
                            </td>
                            <td>
                              <EditKeep
                                newEdit={d.keepEdit}
                                editId={d.editId}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <th
                          colSpan={10}
                          rowSpan={1}
                          style={{
                            fontWeight: "normal",
                            paddingTop: 0,
                            paddingBottom: 0,
                            margin: 0,
                          }}
                        >
                          <Pagination
                            size="sm"
                            className="m-1"
                            style={{ float: "right" }}
                          >
                            <Form.Select
                              size="sm"
                              style={{
                                maxWidth: "140px",
                                borderRight: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                              onChange={(e) => {
                                setItemsPerPage(e.target.value);
                              }}
                            >
                              <option value={10}>Items/Page</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                            </Form.Select>
                            <Pagination.First
                              onClick={() => handlePaginationClick(1)}
                            />
                            <Pagination.Prev
                              onClick={() =>
                                handlePaginationClick(
                                  activePage > 1 ? activePage - 1 : 1
                                )
                              }
                            />
                            {renderPaginationItems()}
                            <Pagination.Next
                              onClick={() =>
                                handlePaginationClick(
                                  activePage < totalPages
                                    ? activePage + 1
                                    : totalPages
                                )
                              }
                            />
                            <Pagination.Last
                              onClick={() => handlePaginationClick(totalPages)}
                            />
                          </Pagination>
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Card.Subtitle>There are no edits to review.</Card.Subtitle>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
};
export default Edits;
