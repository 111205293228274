import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { TbClipboardCopy } from "react-icons/tb";
import { FaCheck } from "react-icons/fa";

const CopyUrl = ({ url }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Button
      variant={!copied ? "info" : "success"}
      onClick={() => {
        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      {!copied ? <TbClipboardCopy /> : <FaCheck />}
    </Button>
  );
};

export default CopyUrl;
