import { gql } from "@apollo/client";

const AvailableDevicesSubscription = gql`
  subscription AvailableDevices {
    listen(topic: "device_update") {
      query {
        allDevices(condition: { isActive: true }, orderBy: DEVICE_NAME_ASC) {
          nodes {
            id
            latitudeField
            longitudeField
            altitudeField
            orientedImageryType
            headingField
            timeField
            timestampFormat
            deviceName
            fileFormat
            isActive
            dateTimeField
            dateField
          }
        }
      }
    }
  }
`;

export default AvailableDevicesSubscription;
