import { gql } from "@apollo/client";

const CreateNewDeviceMutation = gql`
  mutation createDevice(
    $deviceName: String!
    $fileFormat: String!
    $latitudeField: String!
    $longitudeField: String!
    $altitudeField: String
    $headingField: String
    $orientedImageryType: String
    $timestampFormat: String!
    $timeField: String
    $dateField: String
    $dateTimeField: String
  ) {
    createDevice(
      input: {
        device: {
          deviceName: $deviceName
          fileFormat: $fileFormat
          latitudeField: $latitudeField
          longitudeField: $longitudeField
          altitudeField: $altitudeField
          headingField: $headingField
          orientedImageryType: $orientedImageryType
          timestampFormat: $timestampFormat
          timeField: $timeField
          dateField: $dateField
          dateTimeField: $dateTimeField
        }
      }
    ) {
      device {
        id
        deviceName
        fileFormat
        isActive
        latitudeField
        longitudeField
        altitudeField
        orientedImageryType
        headingField
        dateTimeField
        dateField
        timeField
        timestampFormat
      }
    }
  }
`;

export default CreateNewDeviceMutation;
