import { gql } from "@apollo/client";

const OrientedImagery = gql`
  query OrientedImagery($projectName: String!) {
    orientedPhotos(tprojectName: $projectName) {
      nodes {
        X: x
        Y: y
        Z: z
        SRS: srs
        ImagePath: url
        Name: name
        AcquisitionDate: acquisitionDate
        CameraHeading: cameraHeading
        OrientedImageryType: imageryType
      }
    }
  }
`;

export default OrientedImagery;
