import { gql } from "@apollo/client";

const AddUserMutation = gql`
  mutation addUserMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $admin: Boolean!
  ) {
    addUser(
      input: {
        tfirstName: $firstName
        tlastName: $lastName
        temail: $email
        tpassword: $password
        tadmin: $admin
      }
    ) {
      user {
        appId
        email
        firstName
        lastName
        uploadsByUserId {
          totalCount
        }
      }
    }
  }
`;

export default AddUserMutation;
