import { gql } from "@apollo/client";

const AllEndpoints = gql`
  query AllEndpoints {
    allAvailableEndpoints {
      nodes {
        dateRange
        featureUrl
        coverageUrl
        photoCount
        projectName
      }
    }
  }
`;

export default AllEndpoints;
