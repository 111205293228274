import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/esm/Button";
import AddUserMutation from "../gql/AddUserMutation";

const AddUser = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);
  const [userError, setUserError] = useState(false);

  const [addUser] = useMutation(AddUserMutation);
  //console.log(admin);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await addUser({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          admin: admin,
        },
      });

      if (!response.data.addUser.user.appId) {
        setUserError(true);
        return;
      }

      e.target.reset();
    } catch (error) {
      setUserError(true);
      console.error(error);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Add New User</Card.Title>
      </Card.Header>
      <Card.Body>
        {userError && <Alert>An error occured while adding a new user.</Alert>}
        <Form id="add-user-form" onSubmit={handleSubmit}>
          <Form.Group className="p-2 g-4">
            <Form.Label size="sm">Email</Form.Label>
            <Form.Control
              size="sm"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="p-2 g-4">
            <Form.Label size="sm">First Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="p-2 g-4">
            <Form.Label size="sm">Last Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="p-2 g-4">
            <Form.Label size="sm">Set Password</Form.Label>
            <Form.Control
              size="sm"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="p-4 g-2">
            <Form.Check
              inline
              reverse
              type="switch"
              label="Make Admin"
              className="px-3"
              onChange={(e) => {
                e.target.checked ? setAdmin(true) : setAdmin(false);
              }}
            />
            <Button
              size="sm"
              type="submit"
              form="add-user-form"
              style={{ float: "right", color: "white" }}
            >
              Add User
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddUser;
