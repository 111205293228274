import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import DeleteDevice from "./DeleteDevice";
import { FaCheck } from "react-icons/fa";

const DeviceTable = ({ devices }) => {
  return (
    <Card className="p-0">
      <Card.Header>
        <Card.Title>Devices</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table
          responsive="sm"
          size="sm"
          striped
          hover
          style={{ fontSize: ".7em" }}
        >
          <thead>
            <tr>
              <th>Device Name</th>
              <th>Image Format</th>
              <th>Oriented Imagery</th>
              <th>Latitude Field</th>
              <th>Longitude Field</th>
              <th>Altitude Field</th>
              <th>Heading Field</th>
              <th>Date Field</th>
              <th>Time Field</th>
              <th>Date Time Field</th>
              <th>Format String</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {devices.map((d) => {
              return (
                <tr key={d.id}>
                  <td>{d.deviceName}</td>
                  <td>{d.fileFormat}</td>
                  <td>
                    {d.orientedImageryType ? <FaCheck color="green" /> : <></>}
                  </td>
                  <td>{d.latitudeField}</td>
                  <td>{d.longitudeField}</td>
                  <td>{d.altitudeField}</td>
                  <td>{d.headingField}</td>
                  <td>{d.dateField}</td>
                  <td>{d.timeField}</td>
                  <td>{d.dateTimeField}</td>
                  <td>{d.timestampFormat}</td>
                  <td>
                    <DeleteDevice id={d.id} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default DeviceTable;
