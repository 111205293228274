import { gql } from "@apollo/client";

const LoginMutation = gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(input: { temail: $email, tpassword: $password }) {
      jwt
    }
  }
`;

export default LoginMutation;
