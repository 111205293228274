import React, { useEffect, useState } from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import CurrentUser from "../Administration/gql/CurrentUser";
import CurrentUserSubscription from "../Administration/gql/CurrentUserSubscription";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MappingUrls from "../Modules/MappingUrls";

const GIS = () => {
  const navigate = useNavigate();

  if (localStorage.getItem("photo-token") === null) navigate("/login");

  const [appId, setAppId] = useState("");

  const { loading, error, data, subscribeToMore } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  subscribeToMore({
    document: CurrentUserSubscription,
    variables: { topic: `user_updates:${appId}` },
    updateQuery: () => {
      return true;
    },
  });

  useEffect(() => {
    setAppId(data?.currentUser?.appId);
  }, [data]);

  if (loading) return <Spinner variant="primary" animation="grow"></Spinner>;

  if (error) {
    console.error(error);
    navigate("/login");
    return <Alert>There was an issue retrieving data.</Alert>;
  }

  if (!data.currentUser.isActive) {
    localStorage.removeItem("photo-token");
    navigate("/login");
    return <Alert>You have been disconnected</Alert>;
  }

  if (data.currentUser) {
    return (
      <>
        <Header user={data.currentUser} />
        <Container>
          <Row className="p-2 g-2">
            <MappingUrls />
          </Row>
        </Container>
      </>
    );
  }
};

export default GIS;
