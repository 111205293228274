import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import KeepEditDelta from "./gql/KeepMutation";
import Form from "react-bootstrap/Form";
const Keep = ({ newEdit, oldEdit, newEditId, oldEditId }) => {
  const [newBool, setNewBool] = useState(newEdit);
  const [oldBool, setOldBool] = useState(oldEdit);

  const [pickEditMutation] = useMutation(KeepEditDelta);

  const handleKeepNew = async (dif) => {
    if (!newBool && oldBool) setOldBool(false);

    try {
      await pickEditMutation({ variables: { active: dif, editId: newEditId } });
      setNewBool(dif);
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeepOld = async (dif) => {
    if (!oldBool && newBool) setNewBool(false);
    try {
      await pickEditMutation({ variables: { active: dif, editId: oldEditId } });
      setOldBool(dif);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <span>
      <Form.Check
        inline
        reverse
        checked={oldBool}
        label="Old"
        onChange={(e) => handleKeepOld(e.target.checked)}
      />
      <Form.Check
        inline
        checked={newBool}
        label="New"
        onChange={(e) => handleKeepNew(e.target.checked)}
      />
    </span>
  );
};

export default Keep;
