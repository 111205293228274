import { gql } from "@apollo/client";

const UploadsMutation = gql`
  mutation createUpload(
    $userId: UUID!
    $projectName: String
    $zipFileName: String!
    $zipFileSize: BigInt!
    $bulk: Boolean
  ) {
    createUpload(
      input: {
        upload: {
          userId: $userId
          projectName: $projectName
          zipFileName: $zipFileName
          zipFileSize: $zipFileSize
          isBulk: $bulk
        }
      }
    ) {
      upload {
        id
        presignedUrl {
          url
        }
        uploaded
        zipFileName
        zipFileSize
      }
    }
  }
`;

export default UploadsMutation;
