import React, { useEffect, useState } from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import FormCheck from "react-bootstrap/FormCheck";
import Uploads from "../Modules/Uploads";
// import BulkUploadForm from "../Modules/BulkUploadForm";
import CurrentUser from "../Administration/gql/CurrentUser";
import CurrentUserSubscription from "../Administration/gql/CurrentUserSubscription";
import UploadForm from "../Modules/UploadForm";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import BrokenFile from "../Modules/BrokenFiles";

const Main = () => {
  const navigate = useNavigate();

  const [showMap, setShowMap] = useState(false);
  const [uploadId, setUploadId] = useState();
  // const [bulk, setBulk] = useState(false);
  const [appId, setAppId] = useState("");
  const [projectName, setProjectName] = useState();
  const [brokenFile, setShowBrokenFile] = useState();

  const { loading, error, data, subscribeToMore } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  subscribeToMore({
    document: CurrentUserSubscription,
    variables: { topic: `user_updates:${appId}` },
    updateQuery: () => {
      return true;
    },
  });

  useEffect(() => {
    if (localStorage.getItem("photo-token") === null) navigate("/login");
    setAppId(data?.currentUser?.appId);
  }, [data, navigate]);

  if (loading) return <Spinner variant="primary" animation="grow"></Spinner>;

  if (error) {
    console.error(error);
    navigate("/login");
    return <Alert>There was an issue retrieving data.</Alert>;
  }

  if (!data.currentUser.isActive) {
    localStorage.removeItem("photo-token");
    navigate("/login");
    return <Alert>You have been disconnected.</Alert>;
  }

  if (data.currentUser) {
    return (
      <>
        <Header user={data.currentUser} />
        <Container>
          <Row className="p-2 g-2">
            <Col md={6} xl={4} className="p-2 g-1">
              <UploadForm user={data.currentUser} />
              {/* {bulk ? (
                <BulkUploadForm user={data.currentUser} />
              ) : (
                <UploadForm user={data.currentUser} />
              )}
              <FormCheck
                inline
                reverse
                className="py-3"
                type="switch"
                label="Bulk Upload?"
                onChange={(e) => {
                  e.target.checked ? setBulk(true) : setBulk(false);
                }}
              /> */}
            </Col>
            <Col md={12} xl={8} className="p-2 g-1">
              <Uploads
                user={data.currentUser}
                mapVisible={showMap}
                projectName={projectName}
                setShowMap={setShowMap}
                setUploadId={setUploadId}
                setShowBrokenFile={setShowBrokenFile}
                setProjectName={setProjectName}
                brokenFile={brokenFile}
              />
              <Row className="my-3">
                <Col>
                  {brokenFile && (
                    <BrokenFile projectName={projectName} uploadId={uploadId} />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default Main;
