import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";

const User = ({ user }) => {
  const navigate = useNavigate();

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header>{user.email}</Popover.Header>
      <Popover.Body>
        <ListGroup>
          <ListGroup.Item action to="/" as={Link}>
            Upload
          </ListGroup.Item>
          <ListGroup.Item action to="/gis" as={Link}>
            GIS
          </ListGroup.Item>
          {/* <ListGroup.Item action to="/review" as={Link}>
            Photo Review
          </ListGroup.Item> */}
          {user.isAdmin && (
            <ListGroup.Item action to="/admin" as={Link}>
              Admin
            </ListGroup.Item>
          )}

          <ListGroup.Item
            action
            onClick={() => {
              localStorage.removeItem("photo-token");
              navigate("/login");
            }}
          >
            Logout
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Avatar name={user.firstName} size="35" color="#f8991d" />
    </OverlayTrigger>
  );
};

export default User;
