import { gql } from "@apollo/client";

const AddProjectMutation = gql`
  mutation addProjectMutation($projectName: String!) {
    createProject(input: { project: { projectName: $projectName } }) {
      project {
        id
        isActive
        projectName
      }
    }
  }
`;

export default AddProjectMutation;
