import { gql } from "@apollo/client";

const Projects = gql`
  query Projects {
    allProjects(orderBy: PROJECT_NAME_ASC) {
      nodes {
        id
        projectName
        isActive
        uploadCount: uploadsByProjectName {
          count: totalCount
        }
        photos: photosByProjectName {
          count: totalCount
        }
        addedOn
      }
    }
  }
`;

export default Projects;
