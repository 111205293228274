import { gql } from "@apollo/client";

const ResetPasswordMutation = gql`
  mutation newPassword($userId: UUID!, $password: String!) {
    changePassword(input: { tuserId: $userId, tpassword: $password }) {
      boolean
    }
  }
`;

export default ResetPasswordMutation;
