import React, { useState, useRef, useEffect } from "react";
import DateFormat from "./DateFormat";
import Map, { MapProvider, Marker } from "react-map-gl";
import maplibregl from "maplibre-gl";
import has from "lodash/has";
import get from "lodash/get";

const DeviceChecks = ({
  tags,
  latitudeField,
  longitudeField,
  headingField,
  dateField,
  timeField,
  dateTimeField,
  dateTimeFormat,
  setGpsGood,
  setDateGood,
}) => {
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [heading, setHeading] = useState();

  const mapRef = useRef();

  const [viewState, setViewState] = useState({
    longitude: -81.39623,
    latitude: 28.63524,
    zoom: 12,
  });

  useEffect(() => {
    if (latitudeField && longitudeField && tags) {
      let lat = 28.63524;
      let long = -81.39623;
      let head = 0;
      if (has(tags, latitudeField)) {
        lat = get(tags, latitudeField);
        setLatitude(lat);
      }
      if (has(tags, longitudeField)) {
        long = get(tags, longitudeField);
        setLongitude(long);
      }
      if (has(tags, headingField)) {
        head = get(tags, headingField);
        setHeading(head);
      }

      setViewState({
        longitude: long,
        latitude: lat,
        zoom: 14,
      });
    }
  }, [latitudeField, longitudeField, headingField, tags, setGpsGood]);

  if (
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180
  ) {
    setGpsGood(true);
  }

  return (
    <>
      {latitude && longitude && (
        <MapProvider>
          <Map
            ref={mapRef}
            mapLib={maplibregl}
            mapStyle={`https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json`}
            {...viewState}
            style={{
              height: "300px",
              width: "100%",
            }}
          >
            {latitude && longitude && (
              <Marker
                longitude={longitude}
                latitude={latitude}
                anchor="bottom"
                draggable={false}
                rotation={heading}
                rotationAlignment="map"
                color="green"
              ></Marker>
            )}
          </Map>
        </MapProvider>
      )}

      <DateFormat
        tags={tags}
        dateField={dateField}
        timeField={timeField}
        dateTimeField={dateTimeField}
        dateTimeFormat={dateTimeFormat}
        setDateGood={setDateGood}
      />
    </>
  );
};

export default DeviceChecks;
