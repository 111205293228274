import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Components/Common/Main";
import Login from "./Components/Administration/Authentication/Login";
import NewPassword from "./Components/Administration/Authentication/NewPassword";
import Admin from "./Components/Administration/Admin";
import GIS from "./Components/Common/GIS";
// import QAQC from "./Components/QAQC/Main";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" index element={<Main />} />
        {/* <Route exact path="/review" element={<QAQC />} /> */}
        <Route exact path="/gis" element={<GIS />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/password-update" element={<NewPassword />} />
      </Routes>
    </div>
  );
}

export default App;
