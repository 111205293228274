import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ExifReader from "exifreader";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { IoIosWarning } from "react-icons/io";
import FormatBytes from "../Uploads/utils/FormatBytes";
import DeviceChecks from "./DeviceChecks";
import ShowRawData from "./ShowRawData";
import DateTimeFormatHelp from "./DateTimeFormatHelp";
import CreateNewDeviceMutation from "./gql/CreateNewDeviceMutation";
import GPSFields from "./GPSFields";
import DateTimeFields from "./DateTimeFields";

const NewDeviceForm = () => {
  const [imgSrc, setImgSrc] = useState();
  const [fileType, setFileType] = useState("image/*");
  const [metaTags, setMetaTags] = useState({});
  const [fileSize, setFileSize] = useState(0);
  const [exif, setExif] = useState([]);
  const [gps, setGPS] = useState([]);
  //help
  const [showDateTimeHelp, setShowDateTimeHelp] = useState(false);
  const [showExif, setShowExif] = useState(false);
  //fields
  const [device, setDevice] = useState();
  const [latField, setLatField] = useState();
  const [longField, setLongField] = useState();
  const [altField, setAltField] = useState();
  const [imageryType, setImageryType] = useState();
  const [headField, setHeadField] = useState();
  const [dateField, setDateField] = useState();
  const [timeField, setTimeField] = useState();
  const [dateTimeField, setDateTimeField] = useState();
  const [dateTimeFormat, setDateTimeFormat] = useState();

  //status
  const [dateGood, setDateGood] = useState(false);
  const [gpsGood, setGpsGood] = useState(false);
  //error handling
  const [exifMissing, setExifMissing] = useState(false);
  const [addError, setAddError] = useState(false);

  const [addDevice] = useMutation(CreateNewDeviceMutation);

  const handleSubmit = async () => {
    try {
      const response = await addDevice({
        variables: {
          deviceName: device,
          fileFormat: fileType,
          latitudeField: latField,
          longitudeField: longField,
          altitudeField: altField,
          headingField: headField,
          orientedImageryType: imageryType,
          timestampFormat: dateTimeFormat,
          timeField: timeField,
          dateField: dateField,
          dateTimeField: dateTimeField,
        },
      });

      if (!response.data.createDevice.device.id) {
        setAddError(true);
      } else {
        setDevice();
        setGPS([]);
        setExif([]);
        setMetaTags();
        setExifMissing(false);
        setImgSrc();
        setLatField();
        setLongField();
        setAltField();
        setHeadField();
        setImageryType();
        setDateField();
        setTimeField();
        setDateTimeField();
        setDateTimeFormat();
        setDateGood(false);
        setGpsGood(false);
        setAddError(false);
      }
    } catch (error) {
      setAddError(true);
    }
  };

  const handleImageChange = async (file) => {
    setGPS([]);
    setExif([]);
    setMetaTags();
    setExifMissing(false);
    setImgSrc();
    setLatField();
    setLongField();
    setAltField();
    setHeadField();
    setImageryType();
    setDateField();
    setTimeField();
    setDateTimeField();
    setDateTimeFormat();
    setDateGood(false);
    setGpsGood(false);
    setFileType(file.type);
    setFileSize(file.size);

    if (file) {
      const url = URL.createObjectURL(file);
      setImgSrc(url);
    }
    const buffer = await file.arrayBuffer();
    const tags = ExifReader.load(buffer, {
      expanded: true,
      includeUnknown: true,
      async: true,
    });

    setMetaTags(tags);

    if (!tags || !tags?.exif) {
      setExifMissing(true);
    }

    if (tags?.exif) {
      let output = [];
      const keys = Object.keys(tags.exif);
      keys?.forEach((k) => {
        if (typeof tags["exif"][k] === "object") {
          const key = Object.keys(tags["exif"][k]);
          key?.forEach((j) => {
            output.push({
              key: `exif.${k}.${j}`,
              display: `${k}.${j}`,
              example:
                tags["exif"][k][j].toString().length > 24
                  ? tags["exif"][k][j].toString().substring(0, 24) + "..."
                  : tags["exif"][k][j].toString().substring(0, 24),
            });
          });
        }
      });
      output = output?.sort((a, b) => a.display.localeCompare(b.display));
      setExif(output);
    }

    if (tags?.gps) {
      let output = [];
      const keys = Object.keys(tags.gps);
      keys?.forEach((k) => {
        output.push({
          key: `gps.${k}`,
          display: `${k}`,
        });
      });
      setGPS(output);
    }
  };

  return (
    <Card className="p-0">
      <Card.Header>
        <Card.Title>New Device Setup</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="d-flex justify-content-center align-items-start h-100">
          <Col xs={4} style={{ borderRight: "2px solid #ccc" }}>
            <Card.Subtitle className="mb-2">
              Select File to Begin Automated Setup
            </Card.Subtitle>
            <InputGroup size="sm" className="mb-2">
              <Form.Control
                size="sm"
                type="file"
                accept="image/*"
                isInvalid={exifMissing}
                onChange={(e) => handleImageChange(e.target.files[0])}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text>Type</InputGroup.Text>
              <InputGroup.Text>{fileType}</InputGroup.Text>
              <InputGroup.Text>Size</InputGroup.Text>
              <InputGroup.Text>{FormatBytes(fileSize)}</InputGroup.Text>
              <InputGroup.Text>
                {showExif ? "Hide Exif" : "Show Exif"}
              </InputGroup.Text>
              <InputGroup.Text>
                <Form.Check
                  size="sm"
                  type="switch"
                  aria-label="show-hide-exif"
                  disabled={!exif.length}
                  checked={showExif}
                  onChange={() => setShowExif(!showExif)}
                />
              </InputGroup.Text>
            </InputGroup>
            {exifMissing && (
              <Card.Subtitle className="my-1 text-danger">
                <IoIosWarning />
                The file is missing EXIF data and can not be used for Automated
                Setup
                <IoIosWarning />
              </Card.Subtitle>
            )}
            {imgSrc && <Card.Img src={imgSrc} className="my-2" />}
          </Col>
          <Col xs={4} style={{ borderRight: "2px solid #ccc" }}>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text style={{ width: "40%" }}>
                Device Name
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Device Name"
                required
                disabled={!exif.length}
                value={device}
                onChange={(e) => setDevice(e.target.value)}
              />
            </InputGroup>

            <Card.Subtitle className="mb-2">GPS Fields</Card.Subtitle>
            <GPSFields
              exif={exif}
              gps={gps}
              latField={latField}
              longField={longField}
              altField={altField}
              headField={headField}
              imageryType={imageryType}
              setLatField={setLatField}
              setLongField={setLongField}
              setAltField={setAltField}
              setHeadField={setHeadField}
              setImageryType={setImageryType}
            />

            <Card.Subtitle className="mb-2">Date & Time Fields</Card.Subtitle>
            <DateTimeFields
              exif={exif}
              dateField={dateField}
              timeField={timeField}
              dateTimeField={dateTimeField}
              setDateField={setDateField}
              setTimeField={setTimeField}
              setDateTimeField={setDateTimeField}
            />

            <InputGroup size="sm">
              <InputGroup.Text style={{ width: "40%" }}>
                Date Format
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="yyyy:MM:dd hh:mm:ss"
                value={dateTimeFormat}
                disabled={!exif.length}
                onChange={(e) => {
                  setDateTimeFormat(e.target.value);
                }}
              />
              <Button
                variant="info"
                className="px-2"
                title="Date Time Format Help"
                onClick={() => setShowDateTimeHelp(!showDateTimeHelp)}
              >
                ?
              </Button>
            </InputGroup>

            <Row className="d-flex justify-content-end align-items-center mt-2 px-3">
              {addError && (
                <Card.Text style={{ color: "red" }}>
                  There was an error adding the new device.
                </Card.Text>
              )}
              <Button
                size="sm"
                disabled={!dateGood || !gpsGood}
                style={{ color: "white", width: "25%" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Row>
          </Col>
          {latField &&
            longField &&
            (dateField || timeField || dateTimeField) && (
              <Col xs={4}>
                <DeviceChecks
                  tags={metaTags}
                  latitudeField={latField}
                  longitudeField={longField}
                  headingField={headField}
                  dateField={dateField}
                  timeField={timeField}
                  dateTimeField={dateTimeField}
                  dateTimeFormat={dateTimeFormat}
                  setDateGood={setDateGood}
                  setGpsGood={setGpsGood}
                />
              </Col>
            )}
        </Row>
      </Card.Body>
      <ShowRawData
        showExif={showExif}
        setShowExif={setShowExif}
        metaTags={metaTags}
      />
      <DateTimeFormatHelp
        showDateTimeHelp={showDateTimeHelp}
        setShowDateTimeHelp={setShowDateTimeHelp}
      />
    </Card>
  );
};
export default NewDeviceForm;
