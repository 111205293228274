import { gql } from "@apollo/client";

const ReconcileEdits = gql`
  mutation ReconcileEdits {
    reconcileEdits(input: {}) {
      boolean
    }
  }
`;

export default ReconcileEdits;
