import { gql } from "@apollo/client";

const CollisionQuery = gql`
  query Edits {
    allEditCollisions {
      nodes {
        conEditDate
        conEditId
        conEditor
        conIsActive
        conKeepEdit
        conLatitude
        conLongitude
        conUrl
        editDate
        editId
        editor
        isActive
        keepEdit
        latitude
        longitude
        url
      }
      totalCount
    }
  }
`;

export default CollisionQuery;
