import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginMutation from "../gql/LoginMutation";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useMutation } from "@apollo/client";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const [login] = useMutation(LoginMutation);

  if (localStorage.getItem("photo-token") !== null) {
    localStorage.removeItem("photo-token");
  }

  const handleSubmit = async (e) => {
    if (localStorage.getItem("photo-token") !== null) {
      localStorage.removeItem("photo-token");
    }

    e.preventDefault();

    try {
      const response = await login({
        variables: {
          email: email.toLowerCase(),
          password: password,
        },
      });

      if (!response.data.authenticate.jwt) {
        setErrorMessage("Please Check Email and Password");
        return;
      } else {
        localStorage.setItem("photo-token", response.data.authenticate.jwt);
        navigate("/password-update");
      }
      return;
    } catch (error) {
      console.error(error);
      setErrorMessage("There was an error authenticating.");
      return;
    }
  };

  return (
    <Modal centered show>
      <Modal.Header>
        <Modal.Title>
          <img
            alt="Thompson Consulting Service Logo"
            src="/logo192.png"
            width="65"
            height="65"
          />{" "}
          Thompson Consulting Service
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Alert>{errorMessage}</Alert>}
        <Form onSubmit={handleSubmit} id="loginForm">
          <Form.Group className="mb-3" controlId="loginForm.email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              autoComplete="on"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="loginForm.Password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              autoComplete="on"
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          style={{ color: "white" }}
          type="submit"
          form="loginForm"
        >
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Login;
