import React from "react";
import { useMutation } from "@apollo/client";
import ActiveUserMutation from "../gql/ActiveUserMutation";
import Button from "react-bootstrap/esm/Button";
import { FaUserMinus, FaUserPlus } from "react-icons/fa";

const ActiveToggle = ({ user, cuser }) => {
  const [activeToggle] = useMutation(ActiveUserMutation);
  const handleClick = async () => {
    await activeToggle({
      variables: { userId: user.appId, active: !user.isActive },
    });
  };
  return (
    <Button
      title={user.isActive ? "Deactivate User" : "Activate User"}
      disabled={user.appId === cuser.appId}
      size="sm"
      variant={user.isActive ? "danger" : "success"}
      style={{ color: "white" }}
      onClick={() => handleClick()}
    >
      {user.isActive ? <FaUserMinus /> : <FaUserPlus />}
    </Button>
  );
};

export default ActiveToggle;
