import { gql } from "@apollo/client";

const PhotoUploadMutation = gql`
  mutation createPhotoMutation(
    $uploadId: BigInt!
    $projectName: String!
    $originalFileName: String!
    $url: String!
    $lat: Float!
    $lon: Float!
    $alt: Float
    $head: Float
    $imgType: String
    $photoDateTime: Datetime
    $webname: String!
  ) {
    createPhoto(
      input: {
        photo: {
          uploadId: $uploadId
          projectName: $projectName
          originalFilename: $originalFileName
          url: $url
          latitude: $lat
          longitude: $lon
          altitude: $alt
          heading: $head
          imageryType: $imgType
          photoDatetime: $photoDateTime
          webname: $webname
        }
      }
    ) {
      photo {
        photoId
      }
    }
  }
`;

export default PhotoUploadMutation;
