import React from "react";
import { useMutation } from "@apollo/client";
import DeleteDeviceMutation from "./gql/DeleteDeviceMutation";
import Button from "react-bootstrap/Button";
import { FaTrashAlt } from "react-icons/fa";

const DeleteDevice = ({ id }) => {
  const [deleteDevice] = useMutation(DeleteDeviceMutation);

  const handleClick = async () => {
    try {
      const response = await deleteDevice({
        variables: {
          id: id,
        },
      });

      if (!response.data.deleteDeviceById.device.deviceName) {
      }

      return;
    } catch (error) {}
  };

  return (
    <Button
      size="sm"
      variant="danger"
      onClick={() => handleClick()}
      className="d-flex justify-content-center align-items-center"
      style={{ color: "white", height: "25px" }}
    >
      <FaTrashAlt />
    </Button>
  );
};

export default DeleteDevice;
