import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Status from "./Status";
import User from "./User";

const Header = ({ user }) => {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <img
              alt="Thompson Consulting Service Logo"
              src="/logo192.png"
              width="35"
              height="35"
            />{" "}
          </Link>
        </Navbar.Brand>
        <Navbar.Brand>TCS PhotoLog</Navbar.Brand>
        <Navbar.Collapse></Navbar.Collapse>
        <Nav>
          <User user={user} />
        </Nav>
        <Nav className="d-flex flex-column mx-2">
          <Status />
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
