import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  MdSignalWifiStatusbarNull,
  MdSignalWifiStatusbar2Bar,
  MdSignalWifiStatusbar4Bar,
} from "react-icons/md";
import { GrStatusGoodSmall } from "react-icons/gr";
import StatusQuery from "./gql/StatusQuery";
//import Spinner from "react-bootstrap/esm/Spinner";

const Status = () => {
  const [network, setNetwork] = useState(0);
  const { data, startPolling, networkStatus } = useQuery(StatusQuery, {
    ssr: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setNetwork(networkStatus);
  }, [networkStatus]);

  useEffect(() => {
    startPolling(30000);
  }, [startPolling]);

  return (
    <>
      {network === 8 ? (
        <MdSignalWifiStatusbarNull
          title="Can not reach server"
          fill="red"
          style={{ height: "12px", width: "12px", margin: 0 }}
        />
      ) : network === 7 ? (
        <MdSignalWifiStatusbar4Bar
          title="Connected"
          fill="green"
          style={{ height: "12px", width: "12px", margin: 0 }}
        />
      ) : (
        <MdSignalWifiStatusbar2Bar
          title="Working"
          fill="yellow"
          style={{ height: "12px", width: "12px", margin: 0 }}
        />
      )}
      <span className="p-1"></span>
      <GrStatusGoodSmall
        title={"Available Workers:   " + (data?.workerCount || 0)}
        style={{ height: "12px", width: "12px", margin: 0 }}
        color={
          data?.workerCount >= 3
            ? "green"
            : data?.workerCount < 3 && data?.workerCount > 1
            ? "yellow"
            : "red"
        }
      />
    </>
  );
};

export default Status;
