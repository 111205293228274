import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const GPSFields = ({
  exif,
  gps,
  latField,
  setLatField,
  longField,
  setLongField,
  altField,
  headField,
  imageryType,
  setAltField,
  setHeadField,
  setImageryType,
}) => {
  return (
    <>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text style={{ width: "40%" }}>
          Latitude Field
        </InputGroup.Text>
        <Form.Select
          value={latField}
          onChange={(e) => setLatField(e.target.value)}
          disabled={!exif.length}
        >
          <option value=""></option>
          <optgroup label="GPS">
            {gps.map((g, i) => {
              return (
                <option key={i} value={g.key}>
                  {g.display}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="EXIF">
            {exif?.map((e, i) => {
              return (
                <option key={i} value={e.key}>
                  {e.display} (e.g.: {e.example})
                </option>
              );
            })}
          </optgroup>
        </Form.Select>
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text style={{ width: "40%" }}>
          Longitude Field
        </InputGroup.Text>
        <Form.Select
          value={longField}
          onChange={(e) => setLongField(e.target.value)}
          disabled={!exif.length}
        >
          <option value=""></option>
          <optgroup label="GPS">
            {gps.map((g, i) => {
              return (
                <option key={i} value={g.key}>
                  {g.display}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="EXIF">
            {exif?.map((e, i) => {
              return (
                <option key={i} value={e.key}>
                  {e.display} (e.g.: {e.example})
                </option>
              );
            })}
          </optgroup>
        </Form.Select>
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text style={{ width: "40%" }}>
          Altitude Field
        </InputGroup.Text>
        <Form.Select
          value={altField}
          onChange={(e) => setAltField(e.target.value)}
          disabled={!exif.length}
        >
          <option value=""></option>
          <optgroup label="GPS">
            {gps.map((g, i) => {
              return (
                <option key={i} value={g.key}>
                  {g.display}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="EXIF">
            {exif?.map((e, i) => {
              return (
                <option key={i} value={e.key}>
                  {e.display} (e.g.: {e.example})
                </option>
              );
            })}
          </optgroup>
        </Form.Select>
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text style={{ width: "40%" }}>
          Heading Field
        </InputGroup.Text>
        <Form.Select
          value={headField}
          onChange={(e) => setHeadField(e.target.value)}
          disabled={!exif.length}
        >
          <option value=""></option>
          <optgroup label="GPS">
            {gps.map((g, i) => {
              return (
                <option key={i} value={g.key}>
                  {g.display}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="EXIF">
            {exif?.map((e, i) => {
              return (
                <option key={i} value={e.key}>
                  {e.display} (e.g.: {e.example})
                </option>
              );
            })}
          </optgroup>
        </Form.Select>
      </InputGroup>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Text style={{ width: "40%" }}>Imagery Type</InputGroup.Text>
        <Form.Select
          value={imageryType}
          onChange={(e) => setImageryType(e.target.value)}
          disabled={!exif.length}
        >
          <option value={null}></option>
          <option value="Horizontal">Horizontal</option>
          <option value="360">360</option>
        </Form.Select>
      </InputGroup>
    </>
  );
};

export default GPSFields;
