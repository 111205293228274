import { gql } from "@apollo/client";

const EditSubscription = gql`
  subscription EditSubscription {
    listen(topic: "qaqc_update") {
      query {
        allQaqcs(orderBy: EDIT_DATE_DESC) {
          nodes {
            id
            editId
            editor
            editDate
            isActive
            keepEdit
            photoById {
              url
              isActive
            }
            url
            projectName
            uploadId
          }
          totalCount
        }
      }
    }
  }
`;

export default EditSubscription;
