import { gql } from "@apollo/client";

const DeleteDeviceMutation = gql`
  mutation deleteDeviceById($id: BigInt!) {
    deleteDeviceById(input: { id: $id }) {
      device {
        deviceName
      }
    }
  }
`;

export default DeleteDeviceMutation;
