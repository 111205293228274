import React from "react";
import { useMutation } from "@apollo/client";
import AdminToggleMutation from "../gql/AdminToggleMutation";
import Button from "react-bootstrap/esm/Button";
import { FaUser, FaUserTie } from "react-icons/fa";

const AdminToggle = ({ user, cuser }) => {
  const [adminToggle] = useMutation(AdminToggleMutation);
  const handleClick = async () => {
    await adminToggle({
      variables: { userId: user.appId, admin: !user.isAdmin },
    });
  };
  return (
    <Button
      title={user.isAdmin ? "Demote User" : "Make Admin"}
      disabled={user.appId === cuser.appId}
      size="sm"
      variant={user.isAdmin ? "warning" : "success"}
      style={{ color: "white" }}
      onClick={() => handleClick()}
    >
      {user.isAdmin ? <FaUser /> : <FaUserTie />}
    </Button>
  );
};

export default AdminToggle;
