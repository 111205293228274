import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Table from "react-bootstrap/Table";

const DateTimeFormatHelp = ({ showDateTimeHelp, setShowDateTimeHelp }) => {
  return (
    <Offcanvas
      show={showDateTimeHelp}
      placement="end"
      scroll={true}
      onHide={() => setShowDateTimeHelp(false)}
      className="w-50"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Date Time Format Help</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <section>
          <p>
            The below table is a guide for formating a timestamp so that it can
            be parsed by the system. The most common patterns are highlighted
            and bolded below.
          </p>
          <p>
            The following patterns are combined into a string that matches the
            date and time pattern pulled from the device.
          </p>

          <p>
            For instance if the device had a date time pattern of 1929-21-12
            03:15:03 the format string would be:
          </p>

          <pre style={{ textAlign: "center" }}>yyyy-dd-MM hh:mm:ss</pre>
          <p>
            Which allows the computer to read and then convert the date and time
            into a usable format in the system.
          </p>
        </section>
        <hr></hr>
        <Table size="sm" striped>
          <thead>
            <tr>
              <th>Unit</th>
              <th>Pattern</th>
              <th>Examples</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Year</td>
              <td>y</td>
              <td>44, 1, 1900, 2017</td>
            </tr>
            <tr>
              <td></td>
              <td>yo</td>
              <td>44th, 1st, 0th, 17th</td>
            </tr>
            <tr>
              <td></td>
              <td>yy</td>
              <td>44, 01, 00, 17</td>
            </tr>
            <tr>
              <td></td>
              <td>yyy</td>
              <td>044, 001, 1900, 2017</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>yyyy</td>
              <td>0044, 0001, 1900, 2017</td>
            </tr>
            <tr>
              <td></td>
              <td>yyyyy</td>
              <td>...</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Month</td>
              <td>M</td>
              <td>1, 2, ...,12</td>
            </tr>
            <tr>
              <td></td>
              <td>Mo</td>
              <td>1st, 2nd, ..., 12th</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>MM</td>
              <td>01, 02, ..., 12</td>
            </tr>
            <tr>
              <td></td>
              <td>MMM</td>
              <td>Jan, Feb, ..., Dec</td>
            </tr>
            <tr>
              <td></td>
              <td>MMMM</td>
              <td>January, February, ..., December</td>
            </tr>
            <tr>
              <td></td>
              <td>MMMMM</td>
              <td>J, F, ..., D</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Day of Month</td>
              <td>d</td>
              <td>1, 2, ..., 31</td>
            </tr>
            <tr>
              <td></td>
              <td>do</td>
              <td>1st, 2nd, ..., 31st</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>dd</td>
              <td>01, 02, ..., 31</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Hour [1-12]</td>
              <td>h</td>
              <td>1, 2, ..., 11, 12</td>
            </tr>
            <tr>
              <td></td>
              <td>ho</td>
              <td>1st, 2nd, ..., 11th, 12th</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>hh</td>
              <td>01, 02, ..., 11, 12</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Hour [0-23]</td>
              <td>H</td>
              <td>1, 2, ..., 23</td>
            </tr>
            <tr>
              <td></td>
              <td>Ho</td>
              <td>0th, 1st, 2nd, ..., 23rd</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>HH</td>
              <td>00, 02, ..., 23</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Hour [0-11]</td>
              <td>K</td>
              <td>1, 2, ..., 11, 0</td>
            </tr>
            <tr>
              <td></td>
              <td>Ko</td>
              <td>1st, 2nd, ..., 11th, 0th</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>KK</td>
              <td>01, 02, ..., 11, 00</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Hour [1-24]</td>
              <td>k</td>
              <td>24, 1, 2,..., 23</td>
            </tr>
            <tr>
              <td></td>
              <td>ko</td>
              <td>24th, 1st, 2nd, ..., 23rd</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>kk</td>
              <td>00, 01, ..., 23</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Minute</td>
              <td>m</td>
              <td>1, 2, ..., 59</td>
            </tr>
            <tr>
              <td></td>
              <td>mo</td>
              <td>0th, 1st, 2nd, ..., 59th</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>mm</td>
              <td>00, 01, ..., 59</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Second</td>
              <td>s</td>
              <td>0, 1, ..., 59</td>
            </tr>
            <tr>
              <td></td>
              <td>so</td>
              <td>0th, 1st, 2nd, ..., 59th</td>
            </tr>
            <tr style={{ fontWeight: "bold", backgroundColor: "#f8991d88" }}>
              <td></td>
              <td>ss</td>
              <td>00, 01, ..., 59</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Seconds Timestamp</td>
              <td>t</td>
              <td>512969520</td>
            </tr>
            <tr>
              <td></td>
              <td>tt</td>
              <td>...</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Milliseconds Timestamp</td>
              <td>T</td>
              <td>512969520900</td>
            </tr>
            <tr>
              <td></td>
              <td>TT</td>
              <td>...</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Timezone (ISO-8601 with Z)</td>
              <td>X</td>
              <td>-08, +0530, Z</td>
            </tr>
            <tr>
              <td></td>
              <td>XX</td>
              <td>-0800, +0530, Z</td>
            </tr>
            <tr>
              <td></td>
              <td>XXX</td>
              <td>-08:00, +05:30, Z</td>
            </tr>
            <tr>
              <td></td>
              <td>XXXX</td>
              <td>-0800, +0530, Z, +123456</td>
            </tr>
            <tr>
              <td></td>
              <td>XXXXX</td>
              <td>-08:00, +05:30, Z, +12:34:56</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Timezone (ISO-8601 w/o Z)</td>
              <td>x</td>
              <td>-08, +0530, +00</td>
            </tr>
            <tr>
              <td></td>
              <td>xx</td>
              <td>-0800, +0530, +0000</td>
            </tr>
            <tr>
              <td></td>
              <td>xxx</td>
              <td>-08:00, +05:30, +00:00</td>
            </tr>
            <tr>
              <td></td>
              <td>xxxx</td>
              <td>-0800, +0530, +0000, +123456</td>
            </tr>
            <tr>
              <td></td>
              <td>xxxxx</td>
              <td>-08:00, +05:30, +00:00, +12:34:56</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Timezone (GMT)</td>
              <td>O...OOO</td>
              <td>GMT-8, GMT+5:30, GMT+0</td>
            </tr>
            <tr>
              <td></td>
              <td>OOOO</td>
              <td>GMT-08:00, GMT+05:30, GMT+00:00</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>AM, PM</td>
              <td>a..aa</td>
              <td>AM, PM</td>
            </tr>
            <tr>
              <td></td>
              <td>aaa</td>
              <td>am, pm</td>
            </tr>
            <tr>
              <td></td>
              <td>aaaa</td>
              <td>a.m., p.m.</td>
            </tr>
            <tr>
              <td></td>
              <td>aaaaa</td>
              <td>a, p</td>
            </tr>
          </tbody>
        </Table>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DateTimeFormatHelp;
