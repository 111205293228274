import { gql } from "@apollo/client";

const CurrentUser = gql`
  query CurrentUser {
    currentUser {
      appId
      firstName
      lastName
      email
      changePassword
      isAdmin
      isActive
    }
  }
`;

export default CurrentUser;
