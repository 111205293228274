import { gql } from "@apollo/client";

const EditQuery = gql`
  query Edits {
    allQaqcs(orderBy: EDIT_DATE_DESC) {
      nodes {
        id
        editId
        editor
        editDate
        isActive
        keepEdit
        photoById {
          url
          isActive
        }
        url
        projectName
        uploadId
      }
      totalCount
    }
  }
`;

export default EditQuery;
