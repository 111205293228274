import { gql } from "@apollo/client";

const AdminToggleMutation = gql`
  mutation AdminToggle($userId: UUID!, $admin: Boolean!) {
    updateUserByAppId(
      input: { userPatch: { isAdmin: $admin }, appId: $userId }
    ) {
      user {
        isAdmin
      }
    }
  }
`;

export default AdminToggleMutation;
