import { gql } from "@apollo/client";

const AvailableProjectsSubscription = gql`
  subscription AvailableProjects {
    listen(topic: "project_update") {
      query {
        allProjects(condition: { isActive: true }, orderBy: PROJECT_NAME_ASC) {
          nodes {
            projectName
          }
        }
      }
    }
  }
`;

export default AvailableProjectsSubscription;
