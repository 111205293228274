import { gql } from "@apollo/client";

const KeepEditDelta = gql`
  mutation UpdateQaQc($editId: BigInt!, $active: Boolean!) {
    updateQaqcByEditId(
      input: { qaqcPatch: { keepEdit: $active }, editId: $editId }
    ) {
      qaqc {
        isActive
      }
    }
  }
`;

export default KeepEditDelta;
