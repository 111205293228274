import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import KeepEditDelta from "./gql/KeepMutation";
import Form from "react-bootstrap/Form";
const EditKeep = ({ newEdit, editId }) => {
  const [newBool, setNewBool] = useState(newEdit);

  const [pickEditMutation] = useMutation(KeepEditDelta);

  const handleKeep = async (dif) => {
    try {
      await pickEditMutation({ variables: { active: dif, editId: editId } });
      setNewBool(dif);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <span>
      <Form.Check
        inline
        reverse
        checked={newBool}
        onChange={(e) => handleKeep(e.target.checked)}
      />
    </span>
  );
};

export default EditKeep;
