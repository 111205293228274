import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import Card from "react-bootstrap/Card";
import { FaCheck } from "react-icons/fa";
import has from "lodash/has";
import get from "lodash/get";

const DateFormat = ({
  tags,
  dateTimeFormat,
  dateField,
  timeField,
  dateTimeField,
  setDateGood,
}) => {
  const [dateTime, setDateTime] = useState();
  const [good, setGood] = useState(false);
  const [checkMessage, setCheckMessage] = useState();

  useEffect(() => {
    let date;
    let time;
    let outDate;

    const re = /[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}:[0-9]{2}/;

    setCheckMessage();
    if (tags && dateTimeField && dateTimeFormat) {
      if (has(tags, dateTimeField)) {
        date = get(tags, dateTimeField);
        try {
          const checkDate = parse(date, dateTimeFormat, new Date());
          outDate = format(checkDate, "yyyy-MM-dd HH:mm:ss");
        } catch (error) {
          setGood(false);
          setCheckMessage("Please Check Date Format String");
        }

        if (outDate?.match(re)) {
          setGood(true);
          setDateGood(true);
        }

        setDateTime(outDate);
      }
    }

    if (tags && dateField && timeField && dateTimeFormat) {
      if (has(tags, dateField)) {
        date = get(tags, dateField);
      }

      if (has(tags, timeField)) {
        time = get(tags, timeField);
      }
      const dateTime = date?.toString() + " " + time?.toString();
      try {
        const checkDate = parse(dateTime, dateTimeFormat, new Date());
        outDate = format(checkDate, "yyyy-MM-dd HH:mm:ss");
      } catch (error) {
        setGood(false);
        setCheckMessage("Please Check Date Format String");
      }

      if (outDate?.match(re)) {
        setGood(true);
        setDateGood(true);
      }

      setDateTime(outDate);
    }
  }, [tags, dateTimeFormat, dateField, timeField, dateTimeField, setDateGood]);

  return (
    <Card className="my-2">
      <Card.Body className="text-center">
        <Card.Subtitle>Date Time Parsing Check</Card.Subtitle>
        <Card.Text>
          {dateTime} {good && <FaCheck color="green" />}
        </Card.Text>
        {checkMessage && (
          <Card.Text style={{ fontSize: ".9em", color: "red" }}>
            {checkMessage}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default DateFormat;
