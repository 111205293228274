import React, { useState } from "react";
import Header from "../Common/Header";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CurrentUser from "./gql/CurrentUser";
import UsersTable from "./Users/UsersTable";
import ProjectsTable from "./Projects/ProjectsTable";
import Uploads from "./Uploads/Uploads";
//import Keys from "./Keys/Keys";
import Devices from "./Devices/Devices";
import Edits from "./Editing/Edits";

const Admin = () => {
  const navigate = useNavigate();

  const [key, setKey] = useState("users");

  if (localStorage.getItem("photo-token") === null) navigate("/login");

  const { loading, error, data } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  if (loading) return <Spinner variant="primary" animation="grow"></Spinner>;
  if (error) return <Alert>There was an issue retrieving data.</Alert>;
  if (data.currentUser) {
    return (
      <>
        <Header user={data.currentUser}></Header>
        <Container>
          <Row className="p-2 g-0">
            <Col xl={12} className="p-2 g-1">
              <Card>
                <Card.Header>
                  <Nav
                    variant="tabs"
                    defaultActiveKey="users"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="users">Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="projects">Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="devices">Devices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="uploads">Uploads</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="edits">Editing</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="keys">API Keys</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {key === "users" && <UsersTable user={data.currentUser} />}
                  {key === "projects" && <ProjectsTable />}
                  {key === "uploads" && <Uploads />}
                  {key === "devices" && <Devices />}
                  {key === "edits" && <Edits />}
                  {/* {key === "keys" && <Keys></Keys>} */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default Admin;
