import { gql } from "@apollo/client";
const SingleUploadMutation = gql`
  mutation SingleUploadMutation(
    $uploadId: BigInt!
    $projectName: String!
    $fileName: String!
  ) {
    singleUpload(
      input: {
        uploadId: $uploadId
        projectName: $projectName
        fileName: $fileName
      }
    ) {
      url
      urlPath
    }
  }
`;

export default SingleUploadMutation;
