import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const ShowRawData = ({ showExif, setShowExif, metaTags }) => {
  return (
    <Offcanvas
      show={showExif}
      placement="end"
      scroll={true}
      onHide={() => setShowExif(false)}
      className="w-75"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Available Tags</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <section>
          <p>
            The image metadata is processed by the system and converted into an
            easily accessed format.
          </p>{" "}
          <p>
            The id property should be ignored, generally. The value field is the
            pre-processed value from the device.
          </p>{" "}
          <p>
            {" "}
            The description property is the one that you probably want to use.
          </p>
        </section>
        <hr></hr>
        <Offcanvas.Title>GPS Tags</Offcanvas.Title>
        <pre>{JSON.stringify(metaTags?.gps, null, 2)}</pre>
        <hr></hr>
        <Offcanvas.Title>EXIF Tags</Offcanvas.Title>
        <pre>{JSON.stringify(metaTags?.exif, null, 2)}</pre>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ShowRawData;
