import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "react-bootstrap/Pagination";
import * as Dayjs from "dayjs";
import Projects from "../gql/ProjectsQuery";
import ProjectsSubscription from "../gql/ProjectsSubscription";
import AddProject from "./AddProject";
import ActiveProjectToggle from "./ActiveProjectToggle";

const ProjectsTable = () => {
  const [search, setSearch] = useState("");
  const [showDisabled, setShowDisabled] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const { data, loading, error, subscribeToMore } = useQuery(Projects, {
    fetchPolicy: "network-only",
  });

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(
    data?.allProjects?.nodes
      .filter((p) => {
        if (showDisabled) {
          return p;
        }
        return p.isActive;
      })
      .filter((p) => {
        if (search.length > 0) {
          return p.projectName.toLowerCase().includes(search.toLowerCase());
        }
        return p;
      })?.length / itemsPerPage
  );

  subscribeToMore({
    document: ProjectsSubscription,
    updateQuery: (prev) => {
      return prev;
    },
  });

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading)
    return (
      <Spinner
        variant="primary"
        animation="grow"
        style={{ width: "400", height: "400" }}
      />
    );

  if (error) return <Alert>There was an issue with administration.</Alert>;

  if (data) {
    return (
      <Row>
        <Col xl={8}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Card.Title>Projects</Card.Title>
              <InputGroup
                size="sm"
                style={{
                  width: "60%",
                }}
              >
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                  size="sm"
                  placeholder="Project Search"
                  type="search"
                  aria-label="search"
                  aria-describedby="user-search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputGroup.Text>
                  <Form.Check
                    size="sm"
                    type="switch"
                    aria-label="show-hide-disabled-users"
                    onChange={() => setShowDisabled(!showDisabled)}
                  />
                </InputGroup.Text>
                <InputGroup.Text>
                  {showDisabled ? "Hide Disabled" : "Show Disabled"}
                </InputGroup.Text>
              </InputGroup>
            </Card.Header>
            <Card.Body>
              <Card.Subtitle>
                Warning: Disabling a project also deactivates it's map service.
              </Card.Subtitle>
              <Table responsive="md" size="sm" striped hover>
                <thead>
                  <tr>
                    <th>Project Name</th>

                    <th>Uploads</th>
                    <th>Photos</th>
                    <th>Activated On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.allProjects.nodes
                    .filter((p) => {
                      if (showDisabled) {
                        return p;
                      }
                      return p.isActive;
                    })
                    .filter((p) => {
                      if (search.length > 0) {
                        return p.projectName
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      }
                      return p;
                    })
                    .slice(indexOfFirstItem, indexOfLastItem)
                    .map((projects) => (
                      <tr key={projects?.id}>
                        <td
                          style={{
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {projects.projectName}
                        </td>
                        <td>{projects.uploadCount.count}</td>
                        <td>{projects.photos.count}</td>
                        <td>{Dayjs(projects.addedOn).format("M/DD/YYYY")}</td>
                        <td
                          style={{ textAlign: "center" }}
                          className="d-none d-lg-table-cell"
                        ></td>
                        <td style={{ textAlign: "center" }}></td>
                        <td>
                          <ActiveProjectToggle project={projects} />
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <th
                      colSpan={10}
                      rowSpan={1}
                      style={{
                        fontWeight: "normal",
                        paddingTop: 0,
                        paddingBottom: 0,
                        margin: 0,
                      }}
                    >
                      <Pagination
                        size="sm"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <Form.Select
                          size="sm"
                          style={{
                            maxWidth: "140px",
                            borderRight: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          onChange={(e) => {
                            setItemsPerPage(e.target.value);
                          }}
                        >
                          <option value={10}>Items/Page</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                        </Form.Select>
                        <Pagination.First
                          onClick={() => handlePaginationClick(1)}
                        />
                        <Pagination.Prev
                          onClick={() =>
                            handlePaginationClick(
                              activePage > 1 ? activePage - 1 : 1
                            )
                          }
                        />
                        {renderPaginationItems()}
                        <Pagination.Next
                          onClick={() =>
                            handlePaginationClick(
                              activePage < totalPages
                                ? activePage + 1
                                : totalPages
                            )
                          }
                        />
                        <Pagination.Last
                          onClick={() => handlePaginationClick(totalPages)}
                        />
                      </Pagination>
                    </th>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <AddProject />
        </Col>
      </Row>
    );
  }
};

export default ProjectsTable;
