import { gql } from "@apollo/client";

const UploadConfirmMutation = gql`
  mutation updateUploadById(
    $id: BigInt!
    $status: String!
    $count: BigInt
    $size: BigInt
  ) {
    updateUploadById(
      input: {
        uploadPatch: { status: $status, imageCount: $count, zipFileSize: $size }
        id: $id
      }
    ) {
      upload {
        id
      }
    }
  }
`;

export default UploadConfirmMutation;
