import { gql } from "@apollo/client";

const UploadsQuery = gql`
  query AllUploads($userId: UUID) {
    allUploads(
      condition: { userId: $userId }
      orderBy: UPLOADED_DESC
      first: 10
    ) {
      nodes {
        projectName
        imageCount
        id
        status
        uploaded
        zipFileName
        zipFileSize
        imageCount
        isBulk
        failedFilesByUploadId {
          totalCount
        }
      }
    }
  }
`;

export default UploadsQuery;
