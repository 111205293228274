import { gql } from "@apollo/client";

const DisableProjectMutation = gql`
  mutation ProjectDisable($projectName: String!) {
    disableProject(input: { tprojectName: $projectName }) {
      clientMutationId
    }
  }
`;

const EnableProjectMutation = gql`
  mutation ProjectEnable($projectName: String!) {
    enableProject(input: { tprojectName: $projectName }) {
      clientMutationId
    }
  }
`;

export { EnableProjectMutation, DisableProjectMutation };
