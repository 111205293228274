import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import FailedUploads from "./gql/FileUploadFailsQuery";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
//import Pages from "../Common/Pages";

const BrokenFile = ({ projectName, uploadId }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const { data, loading, error } = useQuery(FailedUploads, {
    variables: { uploadId: uploadId },
  });

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(
    data?.allFailedFiles?.nodes?.length / itemsPerPage
  );

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading) return <Spinner></Spinner>;

  if (error) return;

  if (data) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>{projectName} Upload Broken Files</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            The folder path is relative to the folder that you selected for
            upload. For security reasons, this is all that the browser can
            access.
          </Card.Text>
          <Table responsive="md" size="sm" striped hover>
            <thead>
              <tr>
                <th>Folder Path</th>
                <th>File Name</th>
                <th>Issue</th>
              </tr>
            </thead>
            <tbody>
              {data.allFailedFiles.nodes
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((ff, idx) => (
                  <tr key={ff.id}>
                    <td>.\{ff.relativePath.replace("/", "\\")}</td>
                    <td>{ff.filename}</td>
                    <td>{ff.problem}</td>
                  </tr>
                ))}
              <tr>
                <th
                  colSpan={10}
                  rowSpan={1}
                  style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                >
                  <Pagination
                    size="sm"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <Form.Select
                      size="sm"
                      style={{
                        maxWidth: "140px",
                        borderRight: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      onChange={(e) => {
                        setItemsPerPage(e.target.value);
                      }}
                    >
                      <option value={10}>Items/Page</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </Form.Select>
                    <Pagination.First
                      onClick={() => handlePaginationClick(1)}
                    />
                    <Pagination.Prev
                      onClick={() =>
                        handlePaginationClick(
                          activePage > 1 ? activePage - 1 : 1
                        )
                      }
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      onClick={() =>
                        handlePaginationClick(
                          activePage < totalPages ? activePage + 1 : totalPages
                        )
                      }
                    />
                    <Pagination.Last
                      onClick={() => handlePaginationClick(totalPages)}
                    />
                  </Pagination>
                </th>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  }
};

export default BrokenFile;
