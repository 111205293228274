import { gql } from "@apollo/client";

const UploadSubscription = gql`
  subscription UploadSubscription {
    listen(topic: "upload_update") {
      query {
        allUploads(orderBy: UPLOADED_DESC) {
          nodes {
            projectName
            projectByProjectName {
              isActive
            }
            imageCount
            id
            status
            uploaded
            zipFileName
            zipFileSize
            imageCount
            isBulk
            inQaqc
            userByUserId {
              appId
              email
              firstName
              lastName
            }
            failedFilesByUploadId {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default UploadSubscription;
