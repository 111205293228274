import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ReconcileEdits from "./gql/ReconcileMutation";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { BsCheckLg, BsXLg } from "react-icons/bs";

const Reconcile = ({ editCount, collisionCount }) => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [reconcile] = useMutation(ReconcileEdits);

  const handleClick = async () => {
    try {
      const response = await reconcile();
      response.data.reconcileEdits.boolean
        ? setSuccess(true)
        : setFailure(true);
    } catch (error) {
      setSuccess(false);
      setFailure(true);
      console.error(error);
    }
  };

  return (
    <Button
      title={
        success
          ? "Reconcile Completed Successfully"
          : failure
          ? "There was an issue with the reconcile"
          : "Reconcile Edits"
      }
      disabled={editCount < 1 || collisionCount > 0}
      style={{ color: "white" }}
      onClick={() => handleClick()}
    >
      Reconcile{" "}
      {success ? (
        <Badge pill bg="success">
          <BsCheckLg />
        </Badge>
      ) : failure ? (
        <Badge pill bg="danger">
          <BsXLg />
        </Badge>
      ) : (
        ""
      )}
    </Button>
  );
};

export default Reconcile;
