import React from "react";
import { useQuery } from "@apollo/client";
import Card from "react-bootstrap/Card";
import { Row } from "react-bootstrap";
import NewDeviceForm from "./NewDeviceForm";
import DeviceTable from "./DeviceTable";
import Spinner from "react-bootstrap/Spinner";
import AvailableDevices from "./gql/AvailableDevicesQuery";
import AvailableDevicesSubscription from "./gql/AvailableDevicesSubscription";

const Devices = () => {
  const { loading, error, data, subscribeToMore } = useQuery(AvailableDevices);

  subscribeToMore({
    document: AvailableDevicesSubscription,
    updateQuery: (prev) => {
      return prev;
    },
  });

  if (error) return;

  if (loading) {
    return (
      <Spinner
        variant="primary"
        animation="grow"
        style={{ width: "400", height: "400" }}
      />
    );
  }

  if (data) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Devices</Card.Title>
        </Card.Header>
        <Card.Body className="p-4">
          <Row className="mb-4">
            <NewDeviceForm />
          </Row>
          <Row>
            <DeviceTable devices={data.allDevices.nodes} />
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default Devices;
