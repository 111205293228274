import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import AvailableDevices from "./gql/AvailableDevicesQuery";
import AvailableDevicesSubscription from "./gql/AvailableDevicesSubscription";

const DeviceSelect = ({
  setDateTimeField,
  setDateField,
  setTimeField,
  setLonField,
  setLatField,
  setAltField,
  setHeadField,
  setImageryType,
  setDateTimeFormat,
  setFileFormat,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [deviceError, setDeviceError] = useState(false);
  const { loading, error, data, subscribeToMore } = useQuery(AvailableDevices);

  subscribeToMore({
    document: AvailableDevicesSubscription,
    updateQuery: (prev) => {
      return prev;
    },
  });

  const handleSelect = (e) => {
    const key = e.target.value ? e.target.value : 0;
    if (data) {
      if (
        !data.allDevices.nodes[key].latitudeField ||
        !data.allDevices.nodes[key].longitudeField
      ) {
        setDeviceError(true);
        setErrorMessage("Error: Device was configured without GPS Fields.");
      }
      setLatField(data.allDevices.nodes[key]?.latitudeField);
      setLonField(data.allDevices.nodes[key]?.longitudeField);
      setAltField(data.allDevices.nodes[key]?.altitudeField);
      setHeadField(data.allDevices.nodes[key]?.headingField);
      setImageryType(data.allDevices.nodes[key]?.orientedImageryType);
      setDateTimeField(data.allDevices.nodes[key]?.dateTimeField);
      setDateField(data.allDevices.nodes[key]?.dateField);
      setTimeField(data.allDevices.nodes[key]?.timeField);
      setDateTimeFormat(data.allDevices.nodes[key]?.timestampFormat);
      setFileFormat(data.allDevices.nodes[key]?.fileFormat);
    }
  };

  useEffect(() => {
    if (data) {
      setLatField(data.allDevices.nodes[0]?.latitudeField);
      setLonField(data.allDevices.nodes[0]?.longitudeField);
      setAltField(data.allDevices.nodes[0]?.altitudeField);
      setHeadField(data.allDevices.nodes[0]?.headingField);
      setImageryType(data.allDevices.nodes[0]?.orientedImageryType);
      setDateTimeField(data.allDevices.nodes[0]?.dateTimeField);
      setDateField(data.allDevices.nodes[0]?.dateField);
      setTimeField(data.allDevices.nodes[0]?.timeField);
      setDateTimeFormat(data.allDevices.nodes[0]?.timestampFormat);
      setFileFormat(data.allDevices.nodes[0]?.fileFormat);
    }
  }, [
    data,
    setLatField,
    setLonField,
    setAltField,
    setHeadField,
    setImageryType,
    setDateField,
    setTimeField,
    setDateTimeField,
    setDateTimeFormat,
    setFileFormat,
  ]);

  if (error) {
    setErrorMessage("There was an error retrieving the devices.");
    setDeviceError(true);
  }

  if (loading) {
    return (
      <Container>
        <Spinner
          variant="primary"
          animation="grow"
          style={{ width: "400", height: "400" }}
        />
      </Container>
    );
  }
  if (data) {
    return (
      <>
        <InputGroup size="sm">
          <InputGroup.Text>Camera</InputGroup.Text>
          <Form.Select
            size="sm"
            title="Select Project"
            id="device-picker"
            onChange={handleSelect}
          >
            {data.allDevices?.nodes?.length < 1 && (
              <option>Please Setup Devices</option>
            )}
            {data?.allDevices?.nodes.map((p, k) => {
              return (
                <option key={k} value={k}>
                  {p.deviceName}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
        {deviceError && <Alert>{errorMessage}</Alert>}
      </>
    );
  }
};

export default DeviceSelect;
