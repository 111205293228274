import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import Container from "react-bootstrap/esm/Container";
import Spinner from "react-bootstrap/esm/Spinner";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import UploadSubscription from "./gql/UploadsSubscription";
import UploadsQuery from "./gql/UploadsQuery";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import * as Dayjs from "dayjs";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import { RiFileDamageFill } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import FormatBytes from "./utils/FormatBytes";
import BrokenFile from "../../Modules/BrokenFiles";

const Uploads = () => {
  const [projectName, setProjectName] = useState();
  const [uploadId, setUploadId] = useState();
  const [show, setShow] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [bytes, setBytes] = useState(0);
  const [images, setImages] = useState(0);
  const [search, setSearch] = useState("");
  const [showDisabled, setShowDisabled] = useState(false);
  const { data, loading, error, subscribeToMore } = useQuery(UploadsQuery);

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(
    data?.allUploads?.nodes
      ?.filter((p) => {
        if (showDisabled) {
          return p;
        }
        return p.projectByProjectName.isActive;
      })
      .filter((p) => {
        if (search.length > 0) {
          return (
            p.projectName.toLowerCase().includes(search.toLowerCase()) ||
            p.userByUserId.firstName
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            p.userByUserId.lastName.toLowerCase().includes(search.toLowerCase)
          );
        }
        return p;
      })?.length / itemsPerPage
  );

  subscribeToMore({
    document: UploadSubscription,
    updateQuery: () => {
      return true;
    },
  });

  useMemo(() => {
    if (data) {
      const fileSize = data?.allUploads?.nodes?.reduce((sum, a) => {
        return sum + parseInt(a.zipFileSize);
      }, 0);
      const numPhotos = data?.allUploads?.nodes?.reduce((sum, a) => {
        return sum + parseInt(a.imageCount);
      }, 0);
      setImages(numPhotos);
      setBytes(fileSize);
    }
  }, [data]);

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading) {
    return (
      <Container>
        <Spinner
          variant="primary"
          animation="grow"
          style={{ width: "400", height: "400" }}
        />
      </Container>
    );
  }

  if (error) {
    return <Alert>There was an error retrieving uploads.</Alert>;
  }

  if (data) {
    return (
      <>
        <Modal show={show} size="xl" onHide={() => setShow(false)}>
          <Modal.Body>
            <BrokenFile uploadId={uploadId} projectName={projectName} />
          </Modal.Body>
        </Modal>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title>Uploads</Card.Title>
            <InputGroup
              size="sm"
              style={{
                width: "40%",
              }}
            >
              <InputGroup.Text>Search</InputGroup.Text>
              <Form.Control
                size="sm"
                type="search"
                placeholder="Search by Project or User"
                aria-label="search"
                aria-describedby="user-search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputGroup.Text>
                <Form.Check
                  size="sm"
                  type="switch"
                  aria-label="show-hide-disabled-users"
                  onChange={() => setShowDisabled(!showDisabled)}
                />
              </InputGroup.Text>
              <InputGroup.Text>
                {showDisabled ? "Hide Disabled" : "Show Disabled"}
              </InputGroup.Text>
            </InputGroup>
          </Card.Header>
          <Card.Body className="d-flex flex-row justify-content-center align-items-center py-2 mb-0">
            <Card.Title>Upload Statistics</Card.Title>
          </Card.Body>
          <Card.Body
            className="d-flex flex-row justify-content-around align-items-center mt-0"
            style={{ textAlign: "center", borderBottom: "3px solid #CCC" }}
          >
            <Card>
              <Card.Header>
                <Card.Title>Total Photos</Card.Title>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                <Card.Title>{images}</Card.Title>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>S3 Size</Card.Title>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                <Card.Title>{FormatBytes(bytes)}</Card.Title>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>S3 Cost</Card.Title>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                <Card.Title>
                  ${Math.ceil((bytes / 1073741824) * 0.023 * 100) / 100}/Month
                </Card.Title>
              </Card.Body>
            </Card>
          </Card.Body>
          <Card.Body>
            <Table
              responsive="md"
              size="sm"
              striped
              hover
              style={{ fontSize: ".8rem" }}
            >
              <thead>
                <tr span={2} style={{ textAlign: "center" }}>
                  <th>User</th>
                  <th>Project</th>
                  <th>Active</th>

                  <th>Status</th>
                  <th rowSpan={2}>Date Uploaded</th>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th>Count</th>
                  <th>Failed</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.allUploads.nodes
                  .filter((p) => {
                    if (showDisabled) {
                      return p;
                    }
                    return p.projectByProjectName.isActive;
                  })
                  .filter((p) => {
                    if (search.length > 0) {
                      return (
                        p.projectName
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        p.userByUserId.firstName
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        p.userByUserId.lastName
                          .toLowerCase()
                          .includes(search.toLowerCase)
                      );
                    }
                    return p;
                  })
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((upld, idx) => (
                    <tr key={idx}>
                      <td>
                        {upld.userByUserId.firstName}{" "}
                        {upld.userByUserId.lastName}
                      </td>
                      <td>{upld.projectName}</td>
                      <td style={{ textAlign: "center" }}>
                        {upld.projectByProjectName.isActive ? (
                          <BsFillCheckCircleFill fill="green" title="Active" />
                        ) : (
                          ""
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {upld.status === "COMPLETE" ? (
                          upld.status
                        ) : upld.status === "PREPARING" ||
                          upld.status === "NEW" ? (
                          <>
                            <Spinner size="sm" variant="primary" />{" "}
                            {upld.status}
                          </>
                        ) : (
                          upld.status
                        )}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        title={Dayjs(upld.uploaded)
                          .utc(true)
                          .local()
                          .format("M/DD/YYYY h:mm A")}
                      >
                        {Dayjs(upld.uploaded)
                          .utc(true)
                          .local()
                          .format("M/DD/YYYY")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {upld.zipFileName === "N/A"
                          ? "Folder"
                          : upld.zipFileName}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {FormatBytes(upld.zipFileSize)}
                      </td>
                      <td style={{ textAlign: "center" }}>{upld.imageCount}</td>
                      <td style={{ textAlign: "center" }}>
                        {upld.failedFilesByUploadId.totalCount}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <InputGroup>
                          <Button
                            title="Show Failed Uploads"
                            disabled={
                              upld.status !== "COMPLETE" ||
                              upld.failedFilesByUploadId.totalCount < 1
                            }
                            size="sm"
                            variant="warning"
                            style={{ color: "white" }}
                            onClick={() => {
                              setProjectName(upld.projectName);

                              setUploadId(upld.id);
                              setShow(true);
                            }}
                          >
                            <RiFileDamageFill />
                          </Button>
                        </InputGroup>
                      </td>
                    </tr>
                  ))}
                <tr style={{ backgroundColor: "white" }}>
                  <td
                    colSpan={10}
                    rowSpan={1}
                    style={{
                      fontWeight: "normal",
                      paddingTop: 0,
                      paddingBottom: 0,
                      margin: 0,
                      backgroundColor: "white !important",
                    }}
                  >
                    <Pagination
                      size="sm"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <Form.Select
                        size="sm"
                        style={{
                          maxWidth: "140px",
                          borderRight: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>Items/Page</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Form.Select>
                      <Pagination.First
                        onClick={() => handlePaginationClick(1)}
                      />
                      <Pagination.Prev
                        onClick={() =>
                          handlePaginationClick(
                            activePage > 1 ? activePage - 1 : 1
                          )
                        }
                      />
                      {renderPaginationItems()}
                      <Pagination.Next
                        onClick={() =>
                          handlePaginationClick(
                            activePage < totalPages
                              ? activePage + 1
                              : totalPages
                          )
                        }
                      />
                      <Pagination.Last
                        onClick={() => handlePaginationClick(totalPages)}
                      />
                    </Pagination>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default Uploads;
