import { gql } from "@apollo/client";

const AvailableDevices = gql`
  query AvailableDevices {
    allDevices(condition: { isActive: true }, orderBy: DEVICE_NAME_ASC) {
      nodes {
        id
        latitudeField
        longitudeField
        altitudeField
        orientedImageryType
        headingField
        timeField
        timestampFormat
        deviceName
        fileFormat
        isActive
        dateTimeField
        dateField
      }
    }
  }
`;

export default AvailableDevices;
