import { gql } from "@apollo/client";

const UserQuery = gql`
  query AllUsers {
    allUsers {
      nodes {
        id
        appId
        email
        firstName
        lastName
        isActive
        isAdmin
        joinedOn
        uploadsByUserId {
          totalCount
        }
      }
    }
  }
`;

export default UserQuery;
