import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import OrientedImageryButton from "./OrientedImagery";
import CopyUrl from "./CopyUrl";
import AllEndpoints from "./gql/AllEndpointsQuery";

const MappingUrls = () => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const { data, loading, error } = useQuery(AllEndpoints);

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(
    data?.allAvailableEndpoints?.nodes?.filter((p) => {
      if (search.length > 0) {
        return p.projectName.toLowerCase().includes(search.toLowerCase());
      }
      return p;
    })?.length / itemsPerPage
  );

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading)
    return (
      <Container>
        <Spinner
          variant="primary"
          animation="grow"
          style={{ width: "400", height: "400" }}
        />
      </Container>
    );

  if (error) {
    return <Alert>There was an issue retrieving data.</Alert>;
  }

  if (data) {
    return (
      <Card className="p-0">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>Available ArcGIS FeatureServices</Card.Title>

          <Form.Control
            size="sm"
            placeholder="Project Search"
            aria-label="search"
            aria-describedby="user-search"
            onChange={(e) => setSearch(e.target.value)}
            style={{ maxWidth: "300px" }}
          />
        </Card.Header>

        <Card.Body>
          {data.allAvailableEndpoints.nodes
            .filter((p) => {
              if (search.length > 0) {
                return p.projectName
                  .toLowerCase()
                  .includes(search.toLowerCase());
              }
              return p;
            })
            .slice(indexOfFirstItem, indexOfLastItem)
            .map((n, idx) => (
              <Card key={idx} className="mb-4">
                <Card.Header>
                  <Card.Title>{n.projectName}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={9}>
                      <p>
                        <b>Coverage Date Range: </b> {n.dateRange}
                      </p>
                      <b>Photo Count: </b> {n.photoCount}
                      <InputGroup size="sm" className="my-2">
                        <InputGroup.Text>Photos URL:</InputGroup.Text>
                        <Form.Control
                          readOnly
                          value={`${process.env.REACT_APP_GIS_SITE}${n.featureUrl}`}
                        />
                        <CopyUrl
                          url={`${process.env.REACT_APP_GIS_SITE}${n.featureUrl}`}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="my-2">
                        <InputGroup.Text>Coverage URL:</InputGroup.Text>
                        <Form.Control
                          readOnly
                          value={`${process.env.REACT_APP_GIS_SITE}${n.coverageUrl}`}
                        />

                        <CopyUrl
                          url={`${process.env.REACT_APP_GIS_SITE}${n.coverageUrl}`}
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={3} className="text-center">
                      <p>
                        <b>Oriented Imagery CSV</b>

                        <OrientedImageryButton projectName={n.projectName} />
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
        </Card.Body>
        <Card.Footer>
          <Pagination size="sm" className="m-1" style={{ float: "right" }}>
            <Form.Select
              size="sm"
              style={{
                maxWidth: "140px",
                borderRight: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>Items/Page</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Form.Select>
            <Pagination.First onClick={() => handlePaginationClick(1)} />
            <Pagination.Prev
              onClick={() =>
                handlePaginationClick(activePage > 1 ? activePage - 1 : 1)
              }
            />
            {renderPaginationItems()}
            <Pagination.Next
              onClick={() =>
                handlePaginationClick(
                  activePage < totalPages ? activePage + 1 : totalPages
                )
              }
            />
            <Pagination.Last
              onClick={() => handlePaginationClick(totalPages)}
            />
          </Pagination>
        </Card.Footer>
      </Card>
    );
  }
};

export default MappingUrls;
