import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import AvailableProjects from "./gql/AvailableProjectsQuery";
import AvailableProjectsSubscription from "./gql/AvailableProjectsSubscription";

const ProjectSelect = ({ setProjectName, uploading }) => {
  const [projectError, setProjectError] = useState(false);
  const { loading, error, data, subscribeToMore } = useQuery(AvailableProjects);

  subscribeToMore({
    document: AvailableProjectsSubscription,
    updateQuery: (prev) => {
      return prev;
    },
  });

  const handleSelect = (e) => {
    setProjectName(e.target.value);
  };

  useEffect(() => {
    setProjectName(data?.allProjects.nodes[0].projectName);
  }, [data, setProjectName]);

  if (error) return setProjectError(true);

  if (loading) {
    return (
      <Container>
        <Spinner
          variant="primary"
          animation="grow"
          style={{ width: "400", height: "400" }}
        />
      </Container>
    );
  }
  if (data) {
    return (
      <>
        <InputGroup size="sm">
          <InputGroup.Text>Projects</InputGroup.Text>
          <Form.Select
            size="sm"
            title="Select Project"
            id="project-picker"
            onChange={handleSelect}
          >
            <option value={null}>Select Project</option>
            {data.allProjects.nodes.map((p, k) => {
              return (
                <option key={k} value={p.projectName}>
                  {p.projectName}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
        {projectError && (
          <Alert>There was an error retrieving the project list.</Alert>
        )}
      </>
    );
  }
};

export default ProjectSelect;
