import { gql } from "@apollo/client";

const ActiveUserMutation = gql`
  mutation ActiveUser($userId: UUID!, $active: Boolean!) {
    updateUserByAppId(
      input: { userPatch: { isActive: $active }, appId: $userId }
    ) {
      user {
        isActive
      }
    }
  }
`;

export default ActiveUserMutation;
