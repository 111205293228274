import { gql } from "@apollo/client";

const NewPasswordMutation = gql`
  mutation newPassword($password: String!) {
    newPassword(input: { tpassword: $password }) {
      boolean
    }
  }
`;

export default NewPasswordMutation;
