import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import CollisionQuery from "./gql/CollisionQuery";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Image from "react-bootstrap/Image";
import Popover from "react-bootstrap/Popover";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import * as Dayjs from "dayjs";
import Keep from "./CollisionsKeep";
import Button from "react-bootstrap/Button";

const Collisions = ({ setCollisions }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const { data, loading, error, refetch } = useQuery(CollisionQuery, {
    fetchPolicy: "network-only",
  });

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(
    data?.allEditCollisions?.totalCount / itemsPerPage
  );

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading)
    return (
      <Spinner
        variant="primary"
        animation="grow"
        style={{ width: "400", height: "400" }}
      />
    );

  if (error) return <Alert>There was an issue with administration.</Alert>;

  const popover = (id, url) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>
          <Image src={url} alt={id} width={200} />
        </Popover.Body>
      </Popover>
    );
  };

  if (data) {
    setCollisions(data.allEditCollisions.totalCount);
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>
            Conflicts ({data.allEditCollisions.totalCount})
          </Card.Title>
          <Card.Title>
            <Button style={{ color: "white" }} onClick={() => refetch()}>
              Update
            </Button>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {data?.allEditCollisions?.nodes.length > 0 ? (
            <Table
              responsive="md"
              size="sm"
              striped
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th colSpan={4}>Old</th>
                  <th></th>
                  <th colSpan={3}>New</th>
                  <th colSpan={2} rowSpan={2}>
                    Keep
                  </th>
                </tr>
                <tr>
                  <th>Photo ID</th>
                  <th>Edited On</th>
                  <th>Editor</th>
                  <th>Active</th>
                  <th></th>
                  <th>Edited On</th>
                  <th>Editor</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {data.allEditCollisions.nodes
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((d, i) => (
                    <tr key={d.id}>
                      <td>
                        <OverlayTrigger
                          placement="right"
                          key={i}
                          overlay={popover(d.id, d.url)}
                        >
                          <span>{d.id}</span>
                        </OverlayTrigger>
                      </td>
                      <td>{Dayjs(d.editDate).format("YYYY-MM-DD h:mm")}</td>
                      <td>{d.editor}</td>
                      <td>{d.isActive ? "True" : "False"}</td>

                      <td> vs</td>
                      <td>{Dayjs(d.conEditDate).format("YYYY-MM-DD h:mm")}</td>
                      <td>{d.conEditor}</td>
                      <td
                        style={{
                          backgroundColor:
                            d.isActive !== d.conIsActive ? "tomato" : "none",
                        }}
                      >
                        {d.conIsActive ? "True" : "False"}
                      </td>
                      <td style={{ justifyContent: "center" }}>
                        <Keep
                          newEdit={d.conKeepEdit}
                          oldEdit={d.keepEdit}
                          newEditId={d.editId}
                          oldEditId={d.conEditId}
                        />
                      </td>
                    </tr>
                  ))}
                <tr>
                  <th
                    colSpan={10}
                    rowSpan={1}
                    style={{
                      fontWeight: "normal",
                      paddingTop: 0,
                      paddingBottom: 0,
                      margin: 0,
                    }}
                  >
                    <Pagination
                      size="sm"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <Form.Select
                        size="sm"
                        style={{
                          maxWidth: "140px",
                          borderRight: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>Items/Page</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Form.Select>
                      <Pagination.First
                        onClick={() => handlePaginationClick(1)}
                      />
                      <Pagination.Prev
                        onClick={() =>
                          handlePaginationClick(
                            activePage > 1 ? activePage - 1 : 1
                          )
                        }
                      />
                      {renderPaginationItems()}
                      <Pagination.Next
                        onClick={() =>
                          handlePaginationClick(
                            activePage < totalPages
                              ? activePage + 1
                              : totalPages
                          )
                        }
                      />
                      <Pagination.Last
                        onClick={() => handlePaginationClick(totalPages)}
                      />
                    </Pagination>
                  </th>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Card.Subtitle>
              There are no edit collisions to review.
            </Card.Subtitle>
          )}
        </Card.Body>
      </Card>
    );
  }
};

export default Collisions;
